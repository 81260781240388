@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html {
  font-family: 'Open Sans', sans-serif;
}

html, body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /*height: 100vh;*/
  /*max-height: 100vh;*/
  /*overflow: hidden;*/
}
